import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const DeleteConfirmationModal = ({
  show,
  handleClose,
  removeSponsorToBlockList,
}) => {
  return (
    <Modal show={show} size={"lg"} animation={false}>
      <ModalHeader closeButton onHide={handleClose}>
        <Modal.Title>Confirmation</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <h5>
              Are you sure you want to remove this sponsor from the block
              payment reminder list?
            </h5>
          </Col>
        </Row>
      </ModalBody>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
        <Button type="submit" onClick={() => removeSponsorToBlockList()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
