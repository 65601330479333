import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchStudent from "./SearchStudent";
import { getAllStudentsWithoutPagination } from "../../../store/actions/allStudents";
import { Row, Col, Button } from "react-bootstrap";
import Slider from "./Slider";
import { age } from "../../../utils/func";
import SriLankaMap from "../../../components/Reusable/SriLankaMap/SriLankaMap";
import "./StudentProfileManagement.scss";
import EditImages from "./EditImages";
import axios from "../../../axios";
import Loading from "../../../components/Reusable/Loading/Loading";

const filters = {
  pageSize: 2000,
  allTypes: true,
};

const StudentProfileManagement = () => {
  const dispatch = useDispatch();
  const { allStudent } = useSelector((state) => state.allStudent);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [salesForceStudentDetails, setSalesForceStudentDetails] =
    useState(null);
  const { student, stLoading, loading, data } = useSelector(
    (state) => state.students
  );
  const studentDetails = student?.data;
  const stringDetail = studentDetails?.detail;
  const detail =
    typeof stringDetail === "string" ? JSON.parse(stringDetail) : stringDetail;

  let address = [];
  detail?.Permanent_District__c && address.push(detail?.Permanent_District__c);
  detail?.Permanent_Province__c && address.push(detail?.Permanent_Province__c);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getAllStudentsWithoutPagination());
  }, []);

  const getDataFromSalesforce = async (salesforceId) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `beneficiaries/get/direct/salesforce/${salesforceId}`
      );
      console.log("data", data);
      setSalesForceStudentDetails(data);
      setEditModalOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loading page />}
      <div className="HeaderTextCMS">
        Student Profile Management
        <div className="Filters">
          <btn
            className="btn btn-success"
            to="create"
            onClick={() => setOpenAddModal(true)}
          >
            Search
          </btn>
        </div>
      </div>
      <SearchStudent
        show={openAddModal}
        handleClose={() => {
          setOpenAddModal(false);
        }}
      />
      <EditImages
        show={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        student={salesForceStudentDetails}
      />
      {/* <DeleteConfirmationModal
        show={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      /> */}
      <div className="WhiteContent">
        <div className="StudentProfile">
          {studentDetails ? (
            <Row>
              <Col md={4}>
                <Slider student={studentDetails} />
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={12} className="d-flex justify-content-end">
                    <Button
                      onClick={() =>
                        getDataFromSalesforce(studentDetails?.salesforceId)
                      }
                    >
                      Edit
                    </Button>
                  </Col>
                  <Col md={12}>
                    <h1>
                      <span className="font-weight-bold">
                        {studentDetails?.firstName}
                      </span>{" "}
                      {studentDetails?.lastName}
                    </h1>

                    {detail?.Student_Tagline__c && (
                      <p className="font-italic">
                        {detail?.Student_Tagline__c ?? "-"}
                      </p>
                    )}
                  </Col>
                  <Col md={8} className="mt-4">
                    <p>
                      <strong>Age: </strong>
                      {age(studentDetails?.birthday ?? "-")}
                    </p>
                    <p>
                      <strong>Location: </strong>
                      {address ? address.join(", ") : "-"}
                    </p>
                    <p>
                      <strong>Current Academic Level: </strong>
                      {detail?.Current_Academic_Level_Grade_Stream__c ?? "-"}
                    </p>
                    <br />
                    <br />
                  </Col>
                  {detail?.Permanent_District__c && (
                    <Col md={4}>
                      <SriLankaMap
                        district={detail?.Permanent_District__c ?? "-"}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          ) : (
            <div>No select student</div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudentProfileManagement;
