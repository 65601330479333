import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";
import axios from "../../../axios";

const AddSponsorToBlockReminderListModal = ({
  show,
  handleClose,
  usersList,
  getAllPaymentRemindersBlockUsersList
}) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const addSponsorToBlockList = async () => {
    try {
      const response = await axios.put(`user/${selectedUser?.value}`, {
        isPaymentReminderBlock: true,
      });
      console.log('response', response);
      getAllPaymentRemindersBlockUsersList()
      return response.data;
    } catch (error) {
      return error;
    }finally{
      setSelectedUser(null);
      handleClose();
    }
  };

  return (
    <Modal show={show} size={"lg"} animation={false}>
      <ModalHeader
        closeButton
        onHide={() => {
          setSelectedUser(null);
          handleClose();
        }}
      >
        <Modal.Title>Add sponsor to block payment reminder list</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <FormLabel>Sponsor Name</FormLabel>
              {/* <FormControl /> */}
              <Select
                size="sm"
                isClearable
                options={usersList}
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            setSelectedUser(null);
            handleClose();
          }}
        >
          Close
        </Button>
        <Button type="submit" disabled={!selectedUser} onClick={() => addSponsorToBlockList()}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSponsorToBlockReminderListModal;
