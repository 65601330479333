import React from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteDistributionEvent, getEventList } from "../../../store/actions";
import moment from "moment/moment";

const LoginLogs = () => {
  const dispatch = useDispatch();
  const { eventList } = useSelector((state) => state.distributionEvent);
  useEffect(() => {
    dispatch(getEventList());
  }, []);

  return (
    <>
      <div className="HeaderTextCMS">Login Logs</div>
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Device ID</th>
              <th>Device Type</th>
              <th>Login Status</th>
              <th>IP Address</th>
              <th>Browser Name</th>
              <th>Latitude</th>
              <th>Longitude</th>
              <th width="120">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>Navindu Dilshan</td>
              <td>navindudilshan6@gmail.com</td>
              <td>175.157.45.153</td>
              <td>Desktop-windows</td>
              <td>Success</td>
              <td>175.157.45.153</td>
              <td>Success</td>
              <td>Chrome</td>
              <td>79.45676</td>
              <td>81.97666</td>
              <td>2025-02-20 06-53 AM</td>
            </tr> */}
            {/* {eventList?.data?.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{moment(item.createdAt).format("MMMM D, YYYY")}</td>
                <td>{moment(item.date).format("MMMM D, YYYY")}</td>
                <td>
                  <Link className="btn btn-info mr-2" to={`edit/${item.id}`}>
                    <i className="fa fa-pencil"></i>
                  </Link>
                  <Button
                    variant="danger"
                    onClick={()=>dispatch(deleteDistributionEvent(item.id))}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LoginLogs;
