/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import "./View.scss";
import {
  PaypalButton,
  CardPayButton,
  GiftButton,
} from "../../Reusable/Button/Button";
import Select from "react-select";
import ElfLogo from "../../../assets/user.png";
import ProgressStudents from "../../Reusable/ProgressStudent/ProgressStudents";
import QuickPay from "../../Reusable/Modal/QuickPay/QuickPay";
import PaypalPay from "../../Reusable/Modal/PaypalPay/PaypalPay";
import PaymentMessage from "../../Reusable/Modal/PaymentMessage/PaymentMessage";
import GiftcardPay from "../../Reusable/Modal/GiftcardPay/GiftcardPay";
import { useDispatch } from "react-redux";
import {
  createPaypalBilling,
  getPaypalExecute,
  getSponsorship,
  loginModalHandle,
} from "../../../store/actions";
import moment from "moment";
import { toast } from "react-toastify";
import MiddlePopup from "../../Reusable/Modal/MiddlePopup/MiddlePopup";
import CancelRecurringModal from "../../Reusable/Modal/CancelRecurring/CancelRecurringModal";
import axios from "../../../axios";

function View(props) {
  const [month, setMonth] = useState(1);
  const [tip, setTip] = useState(0);
  const [recurring, setRecurring] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [showPaypal, setShowPaypal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [showGiftcard, setShowGiftcard] = useState(false);
  const [customTip, setCustomTip] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [dollerList, setDolerList] = useState([
    { label: "Custom", value: "custom", selected: false },
    { label: "0%", value: 0, selected: false },
    { label: "5%", value: 5, selected: false },
    { label: "10%", value: 10, selected: true },
    { label: "15%", value: 15, selected: false },
    { label: "20%", value: 20, selected: false },
    { label: "25%", value: 25, selected: false },
  ]);
  const [openCancelRecurringModal, setOpenCancelRecurringModal] =
    useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { sponsorship, student, auth, payment } = props;

  const totalPayment = month * sponsorship.amount + tip;
  const detail = student?.detail;
  const today = moment().format("YYYY-MM-DD");
  const duleDate = moment(props?.sponsorship?.dueDate).format("YYYY-MM-DD");
  const isDue = moment(duleDate).isSameOrBefore(today);
  let payButton;
  //console.log(props.sponsorship);//-----------------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const { location } = useLocation();

  const currentPlanPeriod =
    props.sponsorship?.currentPayment?.currentPlanPeriod;
  const monthlyAmount = props.sponsorship?.currentPayment?.monthlyAmount;
  const lastPayDate = props.sponsorship?.currentPayment?.createdAt;

  const payments = sponsorship?.payments;
  const lastElementOfThePayment = payments
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .slice(-1)[0];
  const sortedPayments = payments?.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const handleMouseEnter = () => {
    if(recurring){
      setShowTooltip(true);
    }else{
      setShowTooltip(false);
    }
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    if (props.sponsorship?.recurring) {
      setRecurring(true);
    }
  }, [props.sponsorship]);

  useEffect(() => {
    if (location?.search && auth.token) {
      var url = new URL(window.location.href);
      var PayerID = url.searchParams.get("PayerID");
      var PlanToken = url.searchParams.get("token");
      var PaymentId = url.searchParams.get("paymentId");
      var subscriptionId = url.searchParams.get("subscription_id");
      window.history.replaceState(null, null, props.history.location.pathname);
      const data = {
        PayerID: PayerID,
        PaymentId: PaymentId,
        beneficiaryId: student.id,
        sponsorshipId: sponsorship.id,
        amount: month * sponsorship.amount,
        type: "student",
        recurring: false,
        month: month,
        trial_end: props?.sponsorship?.dueDate,
      };

      //If not recuring payment
      if (PayerID) {
        dispatch(getPaypalExecute(data, auth.token));
      }
      //If recuring payment
      if (subscriptionId) {
        data.PayerID = undefined;
        data.PaymentId = undefined;
        data.token = PlanToken;
        data.recurring = true;
        data.subscription_id = subscriptionId;
        dispatch(getPaypalExecute(data, auth.token));
      }

      let paymentError = url.searchParams.get("payment_error");
      if (paymentError) {
        toast.error(paymentError, {
          autoClose: 15000,
        });
      }
    }
  }, [auth.token]);

  useEffect(() => {
    handleSelectChange(dollerList.find((e) => e.selected));
  }, [month]);

  useEffect(() => {
    if (payment.data) {
      setShowStripe(false);
      setShowGiftcard(false);
      setShowPopup(true);
      setPaymentData(payment.data);
    }
  }, [payment]);

  const handleModalStripe = () => {
    if (auth.token) {
      setShowStripe(!showStripe);
    } else {
      dispatch(loginModalHandle(true));
    }
  };
  const handleModalPaypal = () => {
    if (auth.token) {
      setShowPaypal(!showPaypal);
    } else {
      dispatch(loginModalHandle(true));
    }
  };
  const handleModalMessage = () => {
    if (auth.token) {
      setShowMessage(!showMessage);
    } else {
      alert("Please login befor payment");
    }
  };
  const handleModalGiftcard = () => {
    if (auth.token) {
      setShowGiftcard(!showGiftcard);
    } else {
      dispatch(loginModalHandle(true));
    }
  };

  const handlePaypalPayment = () => {
    if (auth.token) {
      const data = {
        amount: totalPayment,
        beneficiaryId: student.id,
        recurring: recurring,
        month: month,
        sponsorshipId: sponsorship.id,
        tip: tip,
        trial_end: props?.sponsorship?.dueDate,
      };

      dispatch(createPaypalBilling(data, auth.token));
    } else {
      alert("Please login to the system");
    }
  };

  const handleSelectChange = (e) => {
    let newDolerList;
    if (e) {
      newDolerList = dollerList.map((v) => {
        if (v?.value === e?.value) {
          v.selected = true;
        } else {
          v.selected = false;
        }
        return v;
      });
    } else {
      newDolerList = dollerList.map((v) => {
        v.selected = false;
        return v;
      });
    }

    setDolerList(newDolerList);

    const am = month * sponsorship.amount;
    setTip((am * e.value) / 100);

    if (e.value === "custom") {
      setIsCustom(true);
    }
  };

  const handleCustomTipChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setCustomTip("");
      setTip(0);
      setIsCustom(false);
      return;
    }

    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setCustomTip(value);
      const am = month * sponsorship.amount;
      setTip((am * parsedValue) / 100);
    }
  };

  const formatPlanPeriod = (months) => {
    if (months === 1) {
      return `${months} month`;
    } else if (months < 12) {
      return `${months} months`;
    } else {
      const years = Math.floor(months / 12);
      const remainingMonths = months % 12;
      const yearText = years === 1 ? "1 year" : `${years} years`;
      const monthText =
        remainingMonths === 1 ? "1 month" : `${remainingMonths} months`;

      return remainingMonths > 0 ? `${yearText} and ${monthText}` : yearText;
    }
  };

  const getPaymentPeriodMonth = (paymentArray, id) => {
    let periodLength = 0;
    let found = false;

    paymentArray?.forEach((payment) => {
      if (!found) {
        periodLength += payment.currentPlanPeriod;
        if (payment.id === id) {
          found = true;
        }
      }
    });

    return periodLength;
  };

  let activePayButton =
    (props.sponsorship.recurring && props?.sponsorship?.status === "pending") ||
    (!props.sponsorship.recurring && props?.sponsorship?.status !== "cenceled");

  if (
    props?.sponsorship?.status === "active" ||
    props?.sponsorship?.status === "pending"
  ) {
    payButton = (
      <>
        {activePayButton && (
          <>
            <Row className="justify-content-center mb-3 mt-3">
              <Col md={6}>
                <CardPayButton
                  title={recurring ? "Subscribe with" : "Pay with"}
                  subTitle={
                    recurring
                      ? "Subscribe with Credit Card"
                      : "Pay with Credit Card"
                  }
                  lg
                  onClick={handleModalStripe}
                />
              </Col>
              {/* <Col md={6}>
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {showTooltip && (
                    <div
                      style={{
                        position: "absolute",
                        top: "-15px",
                        left: "80%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#248acf",
                        color: "#FFFFFF",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "0.9rem",
                        whiteSpace: "nowrap",
                        zIndex: 1000,
                      }}
                    >
                      Under Development
                    </div>
                  )}
                  <PaypalButton
                    title={recurring ? "Subscribe with" : "Pay with"}
                    disabled={showTooltip}
                    subTitle={
                      recurring ? "Subscribe with PayPal" : "Pay with PayPal"
                    }
                    lg
                    onClick={()=> recurring ? null : handleModalPaypal()}
                  />
                </div>
              </Col> */}
                <Col md={6}>
                  <PaypalButton
                    title={recurring ? "Subscribe with" : "Pay with"}
                    disabled={showTooltip}
                    subTitle={
                      recurring ? "Subscribe with PayPal" : "Pay with PayPal"
                    }
                    lg
                    onClick={handleModalPaypal}
                  />
              </Col>
            </Row>

            {!recurring && (
              <div className="gittCardBtnArea">
                <h4>Do you have a Gift Card? </h4>
                <GiftButton
                  title="Pay with"
                  subTitle="Pay with Gift Card"
                  onClick={handleModalGiftcard}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  } else if (props?.sponsorship?.status === "canceled") {
    payButton = (
      <Row className="justify-content-center mb-5 mt-5">
        <Col md={12}>
          <Alert variant="warning text-center">
            <i className="fa fa-ban"></i> YOU HAVE HAVE CANCELED THIS
            SPONSORSHIP
          </Alert>
        </Col>
      </Row>
    );
  }

  const handleConfirm = () => {
    axios
      .put("sponsorships/cancel/subscription/" + props.sponsorship.id, {
        headers: { Authorization: "Bearer " + props.token },
      })
      .then((res) => {
        toast.success("Subscription canceled successfully.", {
          autoClose: 8000,
        });
        setOpenCancelRecurringModal(false);
        dispatch(getSponsorship(props.sponsorship.id, props.token));
        setRecurring(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="View">
      <Row>
        <Col md={3}>
          <div className="imageThumb">
            {student.image ? (
              <img src={student?.image} alt={student?.firstName} />
            ) : (
              <img src={ElfLogo} alt={student?.firstName} />
            )}

            <p>
              {detail?.Description}
              {student.id !== 1 && (
                <Link to={`/student/${student.id}`} target="blank">
                  View Profile
                </Link>
              )}
            </p>
          </div>
        </Col>
        <Col md={9} className="mt-4">
          <Row>
            {student.id !== 1 && (
              <Col md={12}>
                <ProgressStudents orange sponsorPeriod student={student} />
              </Col>
            )}

            <Col md={12}>
              {props?.sponsorship?.status === "active" && (
                <Row className="justify-content-center ">
                  <Col md={12}>
                    <Alert variant="success mt-5 p-4">
                      <h5>
                        <strong>
                          YOUR MOST RECENT SPONSORSHIP PAYMENT DETAILS
                        </strong>
                      </h5>
                      <hr className="mt-3"></hr>
                      {/* <h5 className="mt-4">
                        {props.sponsorship.recurring
                          ? "YOU HAVE SPONSORED TO THIS STUDENT FULL TIME"
                          : `YOU HAVE SPONSORED TO THIS STUDENT TILL - ${moment(
                              props?.sponsorship?.dueDate
                            ).format("DD-MM-YYYY")}`}
                      </h5> */}
                      <ul>
                        {/* <li>
                          <strong>Plan</strong>
                          {props.sponsorship.recurring
                            ? `Subscribed for every ${props.sponsorship.currentPlanPeriod} month`
                            : `Pay for ${props.sponsorship.currentPlanPeriod} month`}{" "}
                        </li> */}
                        <li>
                          <strong>Payment Date</strong>
                          {props.sponsorship?.currentPayment
                            ? moment(
                                props.sponsorship?.currentPayment?.createdAt
                              )
                                .local()
                                .format("MMMM DD, yyyy")
                            : ""}
                        </li>
                        <li>
                          <strong>Payment Type</strong>
                          {props.sponsorship.recurring
                            ? "Recurring"
                            : "Non-Recurring"}
                          {props.sponsorship.recurring && (
                            <>
                              <span style={{ marginLeft: "0.3rem" }}>(</span>
                              <span
                                style={{
                                  textDecoration: "underLine",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setOpenCancelRecurringModal(true)
                                }
                              >
                                Cancel Subscription
                              </span>
                              <span>) </span>
                            </>
                          )}
                        </li>
                        <li>
                          <strong>Payment Amount & Period Applied</strong>
                          {/* {props.sponsorship.recurring ? 
                            `$${monthlyAmount*currentPlanPeriod} for 
                            ${formatPlanPeriod(currentPlanPeriod)} 
                            (${currentPlanPeriod > 1 ? 
                              moment(lastPayDate).format("MMMM YYYY") + " - " + moment(lastPayDate).add(currentPlanPeriod - 1, 'months').format("MMMM YYYY")  
                              : moment(lastPayDate).format("MMMM YYYY")})`
                            
                              : `$${monthlyAmount*currentPlanPeriod} for 
                            ${formatPlanPeriod(currentPlanPeriod)} 
                            (${currentPlanPeriod > 1 ? 
                              moment(lastPayDate).format("MMMM YYYY") + " - " + moment(lastPayDate).add(currentPlanPeriod - 1, 'months').format("MMMM YYYY")  
                              : moment(lastPayDate).format("MMMM YYYY")})`
                          } */}
                          {sponsorship.status === "pending" ? (
                            <span>N/A</span>
                          ) : (
                            <span>
                              {" "}
                              $
                              {sponsorship?.amount *
                                sponsorship?.currentPlanPeriod}{" "}
                              for{" "}
                              {lastElementOfThePayment?.currentPlanPeriod === 1
                                ? moment(sponsorship.startDate)
                                    .add(
                                      getPaymentPeriodMonth(
                                        sortedPayments,
                                        lastElementOfThePayment.id
                                      ) - 1,
                                      "months"
                                    )
                                    .format("MMM YYYY")
                                : `${moment(sponsorship.startDate)
                                    .add(
                                      getPaymentPeriodMonth(
                                        sortedPayments,
                                        lastElementOfThePayment?.id
                                      ) -
                                        lastElementOfThePayment?.currentPlanPeriod,
                                      "months"
                                    )
                                    .format("MMM YYYY")} - ${moment(
                                    sponsorship.startDate
                                  )
                                    .add(
                                      getPaymentPeriodMonth(
                                        sortedPayments,
                                        lastElementOfThePayment?.id
                                      ) - 1,
                                      "months"
                                    )
                                    .format("MMM YYYY")}`}
                            </span>
                          )}
                        </li>
                        {props.sponsorship?.currentPayment ? (
                          <>
                            {/* <li>
                              <strong>Last Payment Amount</strong>$
                              {(props.sponsorship?.currentPayment?.amount - props.sponsorship?.currentPayment?.tip).toFixed(2).toLocaleString()}
                            </li> */}
                            <li>
                              <strong>Tip for Educate Lanka Fund</strong>$
                              {(props.sponsorship?.currentPayment?.tip).toFixed(
                                2
                              )}
                            </li>
                            <li>
                              <strong>Next Payment Due Date</strong>
                              {/* {props.sponsorship?.currentPayment
                              ? moment(lastPayDate).add(currentPlanPeriod, 'months').format("MMMM DD, YYYY"): ""} */}
                              {moment(props?.sponsorship?.dueDate)
                                .local()
                                .format("MMMM DD, YYYY")}
                            </li>
                            <li>
                              <strong>Payment Method</strong>
                              {props.sponsorship?.currentPayment?.method ===
                                "gc" && "Gift Card"}
                              {props.sponsorship?.currentPayment?.method ===
                                "pp" && "Paypal"}
                              {props.sponsorship?.currentPayment?.method ===
                                "cc" && "Credit Card"}
                              {props.sponsorship?.currentPayment?.method ===
                                "ml" && "Offline Payment"}
                            </li>
                          </>
                        ) : (
                          <li>Payment details will get updated shortly.</li>
                        )}
                      </ul>
                      <div className="text-right">
                        <a className="btn btn-success mt-4" href="/sponsor/me">
                          Full Payment History
                        </a>
                      </div>
                    </Alert>
                  </Col>
                </Row>
              )}
            </Col>
            {student.id !== 1 && (
              <>
                <Col md={12}>
                  {recurring ? (
                    <p className="mt-5">
                      Today, I am choosing to pay for {student?.firstName}'s
                      sponsorship as per the following payment frequency.
                    </p>
                  ) : (
                    <p className="mt-5">
                      Today, I am choosing to pay for {student?.firstName}'s
                      sponsorship for the following period.
                    </p>
                  )}

                  <ul className="PaymentTab">
                    <li
                      onClick={() =>
                        !props.sponsorship?.recurring && setMonth(1)
                      }
                      className={month === 1 ? "active" : ""}
                    >
                      {recurring ? "MONTHLY" : "1 MONTH"}
                    </li>
                    <li
                      onClick={() =>
                        !props.sponsorship?.recurring && setMonth(3)
                      }
                      className={month === 3 ? "active" : ""}
                    >
                      {recurring ? "EVERY" : ""} 3 Months
                    </li>
                    <li
                      onClick={() =>
                        !props.sponsorship?.recurring && setMonth(6)
                      }
                      className={month === 6 ? "active" : ""}
                    >
                      {recurring ? "EVERY" : ""} 6 Months
                    </li>
                    <li
                      onClick={() =>
                        !props.sponsorship?.recurring && setMonth(12)
                      }
                      className={month === 12 ? "active" : ""}
                    >
                      {recurring ? "EVERY" : ""} 1 Year
                    </li>
                  </ul>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Subscribe for recurring payments (you can cancel anytime)."
                        onChange={() =>
                          !props.sponsorship?.recurring &&
                          setRecurring(!recurring)
                        }
                        className="custom-switch"
                        checked={recurring}
                        style={{
                          color: "#248acf",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                        }}
                      />
                    </Form>
                  </div>
                  {/* <button
                    onClick={() => setRecurring(!recurring)}
                    className={`Check ${recurring && "active"}`}
                  >
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    Subscribe for recurring payments (you can cancel anytime).
                  </button> */}
                </Col>
                <Col md={12}>
                  <div className="priceTitle">
                    <h3>
                      <span>${month * sponsorship.amount}</span>{" "}
                      {recurring ? (month !== 12 ? " Every" : null) : " for"}{" "}
                      {month === 12
                        ? recurring
                          ? " Yearly"
                          : " 1 Year"
                        : month === 1
                        ? month + " Month"
                        : month + " Months"}
                    </h3>
                    <small>
                      100% of your sponsorship will be transferred to{" "}
                      {student?.firstName}
                    </small>
                  </div>
                  <small className="font-italic">
                    As {student?.firstName} advances to the next academic stage
                    (if applicable), the monthly sponsorship amount will
                    increase to reflect the additional financial assistance
                    needed. We will communicate such instances with you in
                    advance.
                  </small>

                  <div className="optianal">
                    I'm adding an optional
                    <span className="optianalSpan">
                      {isCustom ? (
                        <div className="tip-input-wraper">
                          <input
                            className="tip-input"
                            type="text"
                            value={customTip}
                            disabled={props.sponsorship?.recurring}
                            onChange={handleCustomTipChange}
                            min="0"
                          />
                        </div>
                      ) : (
                        <Select
                          options={dollerList}
                          value={dollerList.find((e) => e.selected)}
                          defaultValue={dollerList.find((e) => e.selected)}
                          onChange={(e) => {
                            !props.sponsorship?.recurring &&
                              handleSelectChange(e);
                          }}
                        />
                      )}
                    </span>
                    to support Educate Lanka deliver its programs and scale its
                    impact to more students.
                  </div>
                  {props?.sponsorship?.status === "pending" && (
                    <h3 className="TotalPayment">
                      Total Sponsorship Payment:{" "}
                      <span>${totalPayment.toFixed(2)}</span>
                    </h3>
                  )}

                  {payButton}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      {/* popup message */}
      <MiddlePopup
        show={showPopup}
        onHide={() => setShowPopup(false)}
        modalData={paymentData}
        donationType={"sponsorship"}
      />

      {/* STRIPE MODAL */}
      {showStripe && (
        <QuickPay
          sponsorship={sponsorship}
          tip={tip}
          student={student.id}
          studentName={`${student.firstName} ${student.lastName}`}
          amount={totalPayment}
          month={month}
          recurring={recurring}
          show={showStripe}
          onHide={handleModalStripe}
        />
      )}
      {/* PAYPAL MODAL */}
      {showPaypal && (
        <PaypalPay
          tip={tip}
          sponsorship={sponsorship}
          amount={totalPayment}
          month={month}
          recurring={recurring}
          handlePaypalPayment={handlePaypalPayment}
          show={showPaypal}
          onHide={handleModalPaypal}
        />
      )}
      {/* GIFTCARD MODAL */}
      {showGiftcard && (
        <GiftcardPay
          tip={tip}
          studentId={student.id}
          amount={sponsorship.amount}
          error={payment.error}
          data={payment.data}
          show={showGiftcard}
          onHide={handleModalGiftcard}
          month={month}
        />
      )}
      {/* MESSAGE MODAL */}
      {showMessage && (
        <PaymentMessage
          tip={tip}
          error={payment.error}
          data={payment.data}
          show={showMessage}
          onHide={handleModalMessage}
        />
      )}
      <CancelRecurringModal
        btnText="Confirm"
        title="Recurring Payment Cancellation"
        description="Please confirm your cancellation for this recurring payment subscription."
        show={openCancelRecurringModal}
        handleClose={() => setOpenCancelRecurringModal(false)}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

export default View;
