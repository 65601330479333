import * as actions from "./actionsTypes";
import axios from "../../axios";

const initStudents = () => ({
  type: actions.INIT_STUDENTS,
});

const successStudents = (data, fresh) => ({
  type: actions.SUCCESS_STUDENTS,
  data: data,
  fresh: fresh,
});

const failStudents = (err) => ({
  type: actions.FAIL_STUDENTS,
  err,
});
// const successStudentLimit = (data) => ({
//   type: actions.SUCCESS_STUDENTS_LIMIT,
//   data: data,
// });

const initOneStudent = () => ({
  type: actions.INIT_ONE_STUDENT,
});

const successOneStudent = (data) => ({
  type: actions.SUCCESS_ONE_STUDENT,
  data: data,
});

const failOneStudent = (err) => ({
  type: actions.FAIL_ONE_STUDENT,
  err,
});

export const resetOneStudent = () => ({
  type: actions.RESET_ONE_STUDENTS,
});

export const getAllStudentsWithoutPagination = () => {
  return (dispatch) => {
    dispatch(initStudents());
    axios
      .get(`beneficiaries/get/all-beneficiaries`)
      .then((res) => {
        return dispatch(successStudents(res.data));
      })
      .catch((err) => {
        console.log(err.error);
        return dispatch(failStudents(err.response));
      });
  };
};
