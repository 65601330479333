import React, { useState, useEffect } from "react";
import {
  InputGroup,
  Button,
  Form,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { set } from "lodash";

function CreateSponsorship(props) {
  const [options, setOptions] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { data: students } = useSelector((state) => state.students);

  const [showCalender, setShowCalender] = useState(false);
  const [sponsorshipStartDate, setSponsorshipStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [amount, setAmount] = useState(0);

  let sponsor = null;
  if (selectedStudent?.users.length > 0) {
    sponsor = selectedStudent?.users.find((sp) => {
      return (
        sp?.sponsorship?.status === "active" ||
        sp?.sponsorship?.status === "pending"
      );
    });
  }
  useEffect(() => {
    if (students?.rows?.length > 0) {
      const op = students.rows.map((st) => {
        return {
          value: st.id,
          label: `${st.firstName} ${st.lastName}`,
        };
      });

      setOptions(op);
    }
  }, [students]);
  const handleChange = (e) => {
    if (e.value) {
      const selStudent = students?.rows?.find((st) => st.id === e.value);
      setSelectedStudent(selStudent);
      setAmount(selStudent?.amount ?? 0);
    }
  };
  const formSubmit = (e) => {
    e.preventDefault();
    if (!sponsor && selectedStudent) {
      props.handleCreateSponsorship(
        selectedStudent,
        "student",
        sponsorshipStartDate,
        amount
      );
    }
  };

  const handleCalenderChange = (e) => {
    const date = moment(e).format("YYYY-MM-DD");
    setSponsorshipStartDate(date);
    setShowCalender(false);
  };

  return (
    <div className="OfflinePay">
      <div className="formIn">
        <h4>Create a new sponsorship</h4>

        <Form onSubmit={formSubmit}>
          <Row>
            <Col>
              <InputGroup size="sm" className="ml-3 select">
                <InputGroup.Prepend>
                  <InputGroup.Text>Student</InputGroup.Text>
                </InputGroup.Prepend>
                <Select options={options} onChange={handleChange} />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="ml-1">
                <InputGroup.Prepend>
                  <InputGroup.Text>Date</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  onClick={() => setShowCalender((prev) => !prev)}
                  value={sponsorshipStartDate}
                />
              </InputGroup>
              {showCalender && (
                <div className="calenderWrapper">
                  <Calendar onChange={handleCalenderChange} />
                </div>
              )}
            </Col>
            <Col>
              <InputGroup className="ml-1">
                <InputGroup.Prepend>
                  <InputGroup.Text>Amount</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  name="amount"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  type="number"
                />
                <InputGroup.Append>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col>
              <Button
                variant="success"
                className="ml-3 mr-3"
                type="submit"
                disabled={
                  sponsor || !selectedStudent
                    ? true
                    : false || amount === 0
                    ? true
                    : false
                }
              >
                Create Sponsorship
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {sponsor && (
        <div className="note">
          This student has a <strong>{sponsor.sponsorship.status} </strong>{" "}
          sponsor{" "}
          <strong>
            {sponsor.firstName} {sponsor.lastName}
          </strong>
        </div>
      )}
    </div>
  );
}

export default CreateSponsorship;
