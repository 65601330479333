import React, { useState } from "react";
import Cropper from "react-easy-crop";
import "./CropImage.css";
import getCroppedImg from "./CropImageFunction";

const CropImage = ({ originalImage, setCroppedAreaPixels }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    //console.log(croppedArea, croppedAreaPixels)
  };

  return (
    <div className="crop-image">
      <div className="crop-container">
        <Cropper
          image={originalImage}
          crop={crop}
          zoom={zoom}
          aspect={3 / 3}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </div>
  );
};

export default CropImage;
