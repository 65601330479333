import Home from "../pages/Home/Home";
import StudentSearch from "../pages/StudentSearch/StudentSearch";
import StudentView from "../pages/StudentView/StudentView";
import Sponsorship from "../pages/Sponsorship/Sponsorship";
import SponsorPrivate from "../pages/SponsorPrivate/SponsorPrivate";
import SponsorPublic from "../pages/SponsorPublic/SponsorPublic";
import PoolFund from "../pages/PoolFund/PoolFund";
import Birthday from "../pages/Birthday/Birthday";
import BuyAGitCard from "../pages/BuyAGitCard/BuyAGitCard";
import CreateCampaign from "../pages/CreateCampaign/CreateCampaign";
import ContactUs from "../pages/ContactUs/ContactUs";
import BlankPage from "../pages/BlankPage/BlankPage";
import Campaign from "../pages/Campaign/Campaign";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import PaymentSuccess from "../components/PaymentSuccess/PaymentSuccess";
import SponsorshipAgreement from "../pages/SponsorshipAgreement/SponsorshipAgreement";
import SponsorshipRelationshipAgreement from "../pages/SponsorshipRelationshipAgreement/SponsorshipRelationshipAgreement";
import Page404 from "../components/Reusable/Page404/index";
// import HowWeWork from "../containers/HowWeWork/HowWeWork";
// import Financials from "../containers/Financials/Financials";
// import CMSAboutUs from "../pages/admin/AdminCMS/AboutUs";
// import CMSHome from "../pages/admin/CMSHomePage/CMSHomePage";
// import AddCMSAboutUs from "../pages/admin/AdminCMS/_AddAbout";
// import AddCMSAboutUs from "../pages/admin/AdminCMS/_AddAbout";

import AdminMessages from "../pages/admin/Messages/Messages";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import ViewSponsorship from "../pages/admin/ViewSponsorship/ViewSponsorship";
import Users from "../pages/admin/Users/Users";
import DataSync from "../pages/admin/DataSync/DataSync";
import UserView from "../pages/admin/UserView/UserView";
import CmsSection from "../pages/admin/CMS/CmsSection/Index";
import CmsPages from "../pages/admin/CMS/CmsPages/Index";
import MediaLibrary from "../pages/admin/CMS/MediaLibrary/MediaLibrary";
import HTMLSample from "../pages/admin/CMS/HTMLSample/HTMLSample";
import DistributionEvents from "../pages/admin/DistributionEvent";
import Logs from "../pages/admin/Logs/Logs";
import ExistingUserSignUp from "../pages/ExistingUserLogin/ExistingUserSignUp";
import LoginLogs from "../pages/admin/LoginLogs/LoginLogs";
import BlockReminders from "../pages/admin/BlockReminder/BlockReminders";
import StudentProfileManagement from "../pages/admin/StudentProfileManagement/StudentProfileManagement";

const admin = [
  {
    exact: false,
    path: "/admin/messages",
    component: AdminMessages,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/dashboard",
    component: Dashboard,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/distribution-events/create",
    component: DistributionEvents.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/distribution-events/edit/:id",
    component: DistributionEvents.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/distribution-events",
    component: DistributionEvents.List,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/login-logs",
    component: LoginLogs,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/block-payment-reminders",
    component: BlockReminders,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/student-profile-management",
    component: StudentProfileManagement,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: false,
    path: "/admin/log-details",
    component: Logs,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/sponsorship/:id",
    component: ViewSponsorship,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/sponsorship/:id",
    component: ViewSponsorship,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/users/:id",
    component: UserView,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/users",
    component: Users,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/datasync",
    component: DataSync,
    authReqired: true,
    adminRoutes: true,
  },

  {
    exact: true,
    path: "/admin/cms-sections",
    component: CmsSection.List,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-sections/add",
    component: CmsSection.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-sections/edit/:id",
    component: CmsSection.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-pages",
    component: CmsPages.List,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-pages/add",
    component: CmsPages.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-pages/edit/:id",
    component: CmsPages.Add,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-media",
    component: MediaLibrary,
    authReqired: true,
    adminRoutes: true,
  },
  {
    exact: true,
    path: "/admin/cms-html",
    component: HTMLSample,
    authReqired: true,
    adminRoutes: true,
  },
];

const nonAdmin = [
  {
    exact: false,
    path: "/sponsor/me",
    component: SponsorPrivate,
    authReqired: true,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/edit-campaign/:id",
    component: CreateCampaign,
    authReqired: true,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/success",
    component: PaymentSuccess,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/contact-us",
    component: ContactUs,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/create-campaign",
    component: CreateCampaign,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/buy-a-gift-card",
    component: BuyAGitCard,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/pledge-your-birthday",
    component: Birthday,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/educate-lanka-fund",
    component: PoolFund,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/donate",
    component: PoolFund,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/sponsor-public/:id",
    component: SponsorPublic,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/sponsorship/:id",
    component: Sponsorship,
    authReqired: true,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/student/ref/:refId",
    component: StudentView,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/student/:id",
    component: StudentView,
    authReqired: false,
    adminRoutes: false,
  },

  {
    exact: false,
    path: "/student-list/:pageNumber",
    component: StudentSearch,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/student-list",
    component: StudentSearch,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/sonsorship/agreement",
    component: SponsorshipAgreement,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/sonsorship/relationship-agreement",
    component: SponsorshipRelationshipAgreement,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/campaign/:id",
    component: Campaign,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/about/:slug",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/about",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: true,
    path: "/reset-password",
    component: PasswordReset,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: true,
    path: "/existing-user-signup",
    component: ExistingUserSignUp,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/skills",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/skills/:slug",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/terms-and-conditions",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/privacy-policy",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/fundraise",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/volunteer",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/press",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/downloads",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/financial",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/live-events",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: false,
    path: "/:slug*",
    component: BlankPage,
    authReqired: false,
    adminRoutes: false,
  },
  {
    exact: true,
    path: "/",
    component: Home,
    authReqired: false,
    adminRoutes: false,
  },
  
  {
    exact: false,
    path: "",
    component: Page404,
    authReqired: false,
    adminRoutes: false,
  },
];
export const routes = [...admin, ...nonAdmin];
