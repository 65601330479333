/*eslint-disable*/
import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import Sponsorships from "../../../components/admin/Dashboard/Sponsorships";
import SponsorShipTags from "../../../components/admin/Users/SponsorShipTags";
import CreateSponsorship from "../../../components/admin/UserView/CreateSponsorship";
import DonateToFund from "../../../components/admin/UserView/DonateToFund";
import Profile from "../../../components/admin/UserView/Profile";
import Loading from "../../../components/Reusable/Loading/Loading";
import {
  createSponsorship,
  getAdminAuthSponsor,
  getAllStudents,
} from "../../../store/actions";
import { createManuallySponsorship } from "../../../store/actions/sponsorships";

function UserView() {
  const { token } = useSelector((state) => state.auth);
  const { data: sponsor, loading } = useSelector((state) => state.sponsor);
  const { loading: spcLoading } = useSelector((state) => state.sponsorships);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    const filters = {
      pageSize: 1000,
      allTypes: true,
    };
    dispatch(getAdminAuthSponsor(id, token));
    dispatch(getAllStudents(1, filters));
  }, [dispatch, id, getAllStudents, getAdminAuthSponsor]);

  const handleCreateSponsorship = (
    student,
    type,
    sponsorshipStartDate,
    amount
  ) => {
    const data = {
      sponsorId: id,
      beneficiaryId: student.id,
      amount: parseInt(amount) ?? 0,
      type: type,
      sponsorshipStartDate: sponsorshipStartDate,
    };
    dispatch(createManuallySponsorship(data, token));
  };
  let content = null;

  const list = sponsor?.sponsorships.map((e) => {
    e.user = sponsor;
    return e;
  });

  if (!loading) {
    content = (
      <>
        <CreateSponsorship handleCreateSponsorship={handleCreateSponsorship} />
        <Profile sponsor={sponsor} />
        {/* <DonateToFund
          handleCreateSponsorship={handleCreateSponsorship}
          list={list}
        /> */}
        <Sponsorships list={list} />
      </>
    );
  }
  return (
    <>
      {loading || spcLoading ? <Loading /> : ""}
      {content}
    </>
  );
}

export default UserView;
