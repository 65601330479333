import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Sidebar.scss";
import Logo from "../../../assets/elc-logo.png";
function Sidebar() {
  return (
    <div className="Sidebar">
      <Link to="/" className="logo">
        <img src={Logo} alt="Elf" />
      </Link>
      <ul>
        <li>
          <NavLink exact="true" to="/admin/dashboard">
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/messages">
            Messages
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/users">
            Users
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/distribution-events">
            Distribution Events
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/login-logs">
            Login Logs
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/block-payment-reminders">
            Block Reminder
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/student-profile-management">
            Student Profile Management
          </NavLink>
        </li>
      </ul>
      <ul>
        <li>
          <NavLink to="/admin/cms-sections">CMS-Sections</NavLink>
        </li>
        <li>
          <NavLink to="/admin/cms-pages">CMS-Pages</NavLink>
        </li>
        <li>
          <NavLink to="/admin/cms-media">Media</NavLink>
          <NavLink to="/admin/cms-html">Sample HTML Code</NavLink>
        </li>
      </ul>
      <ul>
        <li>
          <NavLink exact="true" to="/admin/datasync">
            Data Sync
          </NavLink>
        </li>
        <li>
          <NavLink exact="true" to="/admin/log-details">
            Log details
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
