import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOneStudent } from "../../../store/actions";
import CropImage from "./CropImage";
import getCroppedImg from "./CropImageFunction";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const addImageSchema = Yup.object().shape({
  type: Yup.object().required("Image type is required"),
  imageBlob: Yup.string().required("Image is required"),
});

const EditImages = ({ show, handleClose, student }) => {
  const { data: allStudent } = useSelector((state) => state.allStudent);
  //const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const images = student?.detail?.fileCustom.filter(
    (e) => e.Type__c === "Img_Profile" || e.Type__c === "Img_Photo"
  );

  const handleCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        originalImage,
        croppedAreaPixels,
        0
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal show={show} size={"lg"} animation={false}>
      <ModalHeader
        closeButton
        onHide={() => {
          //(null);
          handleClose();
        }}
      >
        <Modal.Title>Edit Images</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Formik
            enableReinitialize
            initialValues={{
              type: "",
              imageBlob: "",
            }}
            validationSchema={addImageSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log("values", values);
              // setSubmitting(true);
              // dispatch(existingRegisterUser(values));
              // setSubmitting(false);
            }}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              resetForm,
              values,
              errors,
              touched,
              isValid,
              dirty,
              ...formik
            }) => (
              <>
                <Col md={12}>
                  <FormGroup>
                    <FormLabel>Image Type</FormLabel>
                    {/* <FormControl /> */}
                    {errors?.type && (
                      <div className="srv-validation-message">
                        {errors?.type}
                      </div>
                    )}
                    <Select
                      size="sm"
                      isClearable
                      options={[
                        {
                          label: "Img_Profile",
                          value: "Img_Profile",
                        },
                        {
                          label: "Img_Photo",
                          value: "Img_Photo",
                        },
                      ]}
                      value={values?.type}
                      onChange={(e) => {
                        //console.log("e", e);
                        setFieldValue("type", e ?? "");
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  {errors.imageBlob && (
                    <div className="srv-validation-message">
                      {errors.imageBlob}
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        const base64 = reader.result;
                        //setOriginalImage(base64);
                        setFieldValue("imageBlob", base64);
                        //console.log("base64", base64);
                      };
                    }}
                  ></input>
                </Col>
                <Col md={12}>
                  {values?.imageBlob !== "" && (
                    <CropImage
                      originalImage={values?.imageBlob}
                      setCroppedAreaPixels={setCroppedAreaPixels}
                    />
                  )}
                </Col>
                <Col md={12} className="d-flex justify-content-end mt-3">
                  <Button
                    variant="success"
                    onClick={() => handleCroppedImage()}
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Add
                  </Button>
                </Col>
              </>
            )}
          </Formik>
          <Col md={12}>
            <div className="WhiteContent mt-3">
              <div className="tableWrapper">
                <table width="100%">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Image</th>
                      <th width="50">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!images?.length ? (
                      <tr>
                        <td colSpan={4}>
                          <div className="text-center">No Images.</div>
                        </td>
                      </tr>
                    ) : (
                      images?.map((img) => (
                        <tr
                          key={img.id}
                          className={img.listed ? "opacity-3" : undefined}
                        >
                          <td>{img.Name}</td>
                          <td>{img?.Type__c}</td>
                          <td>
                            <img
                              src={img?.File_Link__c}
                              width={100}
                              height={100}
                            />
                          </td>
                          <td>
                            {img.listed ? (
                              ""
                            ) : (
                              <Button
                                variant="outline-danger"
                                size="sm"
                                // onClick={() =>
                                //   handleAddStudentToList(img.id)
                                // }
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      {/* <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            setValue(null);
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setSelectedStudent(value);
            dispatch(getOneStudent(value.value));
            handleClose();
          }}
          disabled={!value}
        >
          Add
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default EditImages;
