import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getEventList } from "../../../store/actions";
import AddSponsorToBlockReminderListModal from "./AddSponsorToBlockReminderListModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "../../../axios";

const BlockReminders = () => {
  const dispatch = useDispatch();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [paymentRemindersBlockUsersList, setPaymentRemindersBlockUsersList] =
    useState([]);
  const [deleteUserId, setDeleteUserId] = useState(null);

  useEffect(() => {
    dispatch(getEventList());
    getAllUsersList();
    getAllPaymentRemindersBlockUsersList();
  }, []);

  const getAllUsersList = async () => {
    try {
      const response = await axios.get(`user/all/get-all-users`);
      setUsersList(response?.data?.modifiedUsersList);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const getAllPaymentRemindersBlockUsersList = async () => {
    try {
      const response = await axios.get(
        `user/update/payment-reminder-block-list`
      );
      setPaymentRemindersBlockUsersList(response?.data?.list ?? []);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const removeSponsorToBlockList = async () => {
    try {
      const response = await axios.put(`user/${deleteUserId}`, {
        isPaymentReminderBlock: false,
      });
      getAllPaymentRemindersBlockUsersList();
      return response.data;
    } catch (error) {
      return error;
    } finally {
      setOpenDeleteModal(false);
      setDeleteUserId(null);
    }
  };

  return (
    <>
      <div className="HeaderTextCMS">
        Block Payment Reminders
        <div className="Filters">
          <btn
            className="btn btn-success"
            to="create"
            onClick={() => setOpenAddModal(true)}
          >
            ADD
          </btn>
        </div>
      </div>
      <AddSponsorToBlockReminderListModal
        show={openAddModal}
        handleClose={() => setOpenAddModal(false)}
        usersList={usersList}
        getAllPaymentRemindersBlockUsersList={
          getAllPaymentRemindersBlockUsersList
        }
      />
      <DeleteConfirmationModal
        show={openDeleteModal}
        handleClose={() => {
          setDeleteUserId(null);
          setOpenDeleteModal(false);
        }}
        removeSponsorToBlockList={removeSponsorToBlockList}
      />
      <div className="WhiteContent">
        <table width="100%">
          <thead>
            <tr>
              <th>Sponsor Name</th>
              <th>Email</th>
              <th width="130">Action</th>
            </tr>
          </thead>
          <tbody>
            {paymentRemindersBlockUsersList?.length > 0 ? (
              paymentRemindersBlockUsersList?.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    <Link
                      className="btn btn-danger"
                      onClick={() => {
                        setDeleteUserId(item.id);
                        setOpenDeleteModal(true);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center py-3">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BlockReminders;
