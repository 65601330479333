import React, { useState, useEffect, useMemo } from "react";
import { Modal, Row, Col, Alert } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import valid from "card-validator";

import { Button } from "../../Button/Button";
import { payWithStripe, resetPayment } from "../../../../store/actions";
import Loading from "../../Loading/Loading";
import useYupValidationResolver from "../../../../utils/useYupValidationResolver";
import "./QuickPay.scss";
import LogoIco from "../../../../assets/elf-logo-clr.png";
import addThousandSeparator from "../../../../utils/addThousandSeparator";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const QuickPay = (props) => {
  const {
    giftcard,
    poolfund,
    student,
    studentName,
    amount,
    tip,
    month,
    recurring,
    sponsorship,
    elf,
  } = props;

  const { token } = useSelector((state) => state.auth);
  const payment = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const validationSchema = useMemo(() => {
    const validateObj = {};

    validateObj.card = yup
      .mixed()
      .test("is-card-complete", "Card details are incomplete", (val) => {
        return val?.complete || false;
      })
      .required("Card details are required");

    if (!token) {
      validateObj.firstName = yup.string().required("First name is required");
      validateObj.lastName = yup.string().required("Last name is required");
      validateObj.email = yup.string().email().required("Email is required");
    }
    if (giftcard) {
      validateObj.recipientName = yup
        .string()
        .required("Recipient name is required");
      validateObj.recipientEmail = yup
        .string()
        .email()
        .required("Recipient Email is required");
    }
    return yup.object(validateObj);
  }, []);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    control,
    values,
  } = useForm({ resolver });

  const [data, setData] = useState(null);

  useEffect(() => {
    return () => dispatch(resetPayment());
  }, []);

  useEffect(() => {
    if (payment.data) {
      setData(payment.data);
    }
  }, [payment.data]);

  const handleDataFormSubmit = async (data) => {
    const obj = data;
    obj.card = null;
    if (giftcard) {
      obj.giftcard = giftcard;
      obj.amount = amount;
      obj.studentName = "Gift Card Payment";
    }
    if (poolfund) {
      obj.poolfund = poolfund;
      obj.amount = amount;
      obj.beneficiaryId = 1;
      obj.type = "elf";
      obj.recurring = recurring;
      obj.month = 1;
      obj.studentName = "Educate Lanka Fund";
    }
    if (student) {
      obj.student = true;
      obj.amount = amount;
      obj.beneficiaryId = student;
      obj.type = "student";
      obj.month = month;
      obj.recurring = recurring;
      obj.trial_end = sponsorship?.dueDate;
      obj.studentName = studentName;
    }
    if (!token) {
      obj.user = {};
      obj.user.firstName = data.firstName;
      obj.user.lastName = data.lastName;
      obj.user.email = data.email;
    }
    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, token: stripeToken } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error creating token:", error);
    } else {
      // Send the token.id to your backend for further processing
      console.log("Stripe Token ID:", stripeToken.id);
      obj.stripeTokenId = stripeToken.id;
    }
    dispatch(payWithStripe({ ...obj, tip }));
  };

  let button = (
    <Button
      title={`Donate $${addThousandSeparator(amount)}  ${
        recurring ? `every ${month} month` : ""
      }`}
      full
      lg
    />
  );
  //GIFT CARD BUTTON
  if (giftcard) {
    button = (
      <Button
        title={`Donate $${addThousandSeparator(amount)} and send the gift card`}
        full
        lg
      />
    );
  }

  if (sponsorship) {
    button = (
      <Button
        title={`Pay $${addThousandSeparator(amount)}  
    ${
      recurring
        ? `every ${month === 12 ? 1 : month} ${
            month === 12 ? "year" : month === 1 ? "month" : "months"
          }`
        : `for ${month === 12 ? 1 : month} ${
            month === 12 ? "year" : month === 1 ? "month" : "months"
          }`
    }`}
        full
        lg
      />
    );
  }

  if (elf) {
    button = (
      <>
        <label
          onClick={props?.recurringHandler}
          className={`checkLabel ${recurring ? "recurring" : null}`}
        >
          Donate monthly
        </label>
        <Button
          title={`Donate $${addThousandSeparator(amount)}  ${
            recurring ? "monthly" : ""
          } `}
          full
          lg
        />
      </>
    );
  }

  let content = (
    <>
      {payment.error && <Alert variant="danger">Payment incompleted.</Alert>}
      <form onSubmit={handleSubmit(handleDataFormSubmit)}>
        {!token && (
          <Row>
            <Col md={6} className="inputContainer">
              {errors?.firstName?.message && (
                <p className="errorMessage">{errors.firstName.message}</p>
              )}
              <input
                placeholder="First name"
                type="text"
                {...register("firstName")}
              />
            </Col>

            <Col md={6} className="inputContainer">
              {errors?.lastName?.message && (
                <p className="errorMessage">{errors.lastName.message}</p>
              )}
              <input
                placeholder="Last name"
                name="Last Name"
                type="text"
                {...register("lastName")}
              />
            </Col>
            <Col md={12} className="inputContainer">
              {errors?.email?.message && (
                <p className="errorMessage">{errors.email.message}</p>
              )}

              <input placeholder="Email" type="text" {...register("email")} />
            </Col>
          </Row>
        )}
        {/* ======================GIFT CARD ONLY================ */}
        {giftcard && (
          <>
            <div className="hr"></div>
            <Row>
              <Col md={12} className="inputContainer">
                {errors?.recipientName?.message && (
                  <p className="errorMessage">{errors.recipientName.message}</p>
                )}
                <input
                  type="text"
                  placeholder="Recipient name"
                  {...register("recipientName")}
                />
              </Col>
              <Col md={12} className="inputContainer">
                {errors?.recipientEmail?.message && (
                  <p className="errorMessage">
                    {errors.recipientEmail.message}
                  </p>
                )}
                <input
                  type="text"
                  placeholder="Recipient email"
                  {...register("recipientEmail")}
                />
              </Col>
            </Row>
          </>
        )}
        {/* ======================END GIFT CARD ONLY================ */}

        <Row>
          <Col md={12} className="inputContainer icon">
            {errors?.card && (
              <p className="errorMessage">{errors.card.message}</p>
            )}
            <Controller
              name="card"
              control={control}
              render={({ field }) => (
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                />
              )}
            />
          </Col>
        </Row>

        {button}
      </form>
      <br />
      <br />
    </>
  );

  const modalHeaderContent = () => {
    if (giftcard) {
      return (
        <>
          <h2>Gift Card Donation</h2>
          <small>A gift towards a deserving student's education</small>
        </>
      );
    }
    if (sponsorship) {
      return (
        <>
          <h3>Student Sponsorship Payment</h3>
          <small>
            Investing in their future, one deserving student at a time.
          </small>
        </>
      );
    } else {
      return (
        <>
          <h2>Educate Lanka Fund</h2>
          <small>
            To support micro-scholarships and learning opportunities
          </small>
        </>
      );
    }
  };

  return (
    <Modal {...props} className="QuickPay">
      {payment.loading && <Loading />}
      <Modal.Header closeButton className="pb-0">
        <Modal.Title>
          <div className="TitleContent">
            <h5 style={{ marginTop: "1.5rem" }}>Educate Lanka</h5>
            {/* {elf ? <h2>Educate Lanka Fund</h2> : <h2>Gift Card Donation</h2>}
            {elf? <small>To support micro-scholarships and learning opportunities</small> : <small>A gift towards a deserving student's education</small>} */}
            {modalHeaderContent()}
            <img src={LogoIco} width="50" alt="logo" />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-4">{content}</Modal.Body>
    </Modal>
  );
};

export default QuickPay;
