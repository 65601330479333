import React from "react";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOneStudent } from "../../../store/actions";

const SearchStudent = ({ show, handleClose }) => {
  const { data: allStudent } = useSelector((state) => state.allStudent);
  const [value, setValue] = React.useState(null);
  const dispatch = useDispatch();

  return (
    <Modal show={show} size={"lg"} animation={false}>
      <ModalHeader
        closeButton
        onHide={() => {
          setValue(null);
          handleClose();
        }}
      >
        <Modal.Title>Add Student profile Details</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <FormLabel>Student Name</FormLabel>
              {/* <FormControl /> */}
              <Select
                size="sm"
                isClearable
                options={allStudent?.length > 0 ? allStudent?.map((e) => ({
                  label: e.firstName + " " + e.lastName,
                  value: e.id,
                })) : []}
                value={value}
                onChange={(e) => {
                  setValue(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            setValue(null);
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            dispatch(getOneStudent(value.value));
            handleClose();
          }}
          disabled={!value}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchStudent;
