import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Photo from "../../../assets/user.png";

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus:true,
  showIndicators:true,
  infiniteLoop:true,
  showThumbs:true,
  useKeyboardArrows:true,
  autoPlay:true,
  stopOnHover:true,
  swipeable:true,
  dynamicHeight:true,
  emulateTouch:true,
  autoFocus:false,
  thumbWidth:80,
  selectedItem:0,
  interval:2000,
  transitionTime:500,
  swipeScrollTolerance:5,
});

export default function Slider({ student }) {
  const images = student?.detail?.fileCustom.filter(
    (e) => e.Type__c === "Img_Profile" || e.Type__c === "Img_Photo"
  );
  console.log("images", images);
  return (
    <Carousel {...getConfigurableProps()} animationHandler="fade" swipeable={false} className="StudentProfileSlider">
      {images?.length ? (
        images?.map((image) => (
          <div key={image.Id} className="StudentSliderImage" style={{backgroundImage:`url('${image.File_Link__c}')`}}>
            <img src={image.File_Link__c} alt=""  />
          </div>
        ))
      ) : (
        <div className="StudentSliderImage" style={{backgroundImage:`url('${Photo}')`}}></div>
      )}
    </Carousel>
  );
}
